.html {
    --bg-color: #e44f26;
    --bg-color-light: #ffd4c8;
    --text-color-hover: #fff;
    --box-shadow-color: rgba(255, 215, 97, 0.48);
  }
  
  .card {
    width: 240px;
    height: 321px;
    background: white;
    border-top-right-radius: 10px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-out;
    text-decoration: none;
  }
  
  .card:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.11),
      0 5px 15px var(--box-shadow-color);
  }
  
  .card:hover .cor__cobertura {
    transform: scale(4) translateZ(0);
  }
  
  .card:hover .circulo {
    background: var(--main-color);
    border: 2px dotted var(--secondary-color);
  }
  
  .card:hover .circulo:after {
    background: var(--bg-se);
  }
  
  .card:hover p {
    color: black;
  }
  
  .card:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
      0 15px 24px var(--box-shadow-color);
  }
  
  .circulo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
  }
  
  
  
  .cor__cobertura {
    width: 114px;
    height: 114px;
    position: absolute;
    border-radius: 50%;
    background:white;
    top: 70px;
    left: 50px;
    z-index: 0;
    transition: transform 0.3s ease-out;
  }
  


  /* ------------------------------------------------------ */

  .card2 {
    width: 240px;
    height: 321px;
    background: red;
    border-top-right-radius: 10px;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.04);
    transition: all 0.3s ease-out;
    text-decoration: none;
  }
  
  .card2:hover {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.11),
      0 5px 15px var(--box-shadow-color);
  }
  
  .card2:hover .cor__cobertura {
    transform: scale(4) translateZ(0);
  }
  
  .card2:hover .circulo {
    background: var(--main-color);
    border: 2px dotted var(--secondary-color);
  }
  
  .card2:hover .circulo:after {
    background: var(--bg-se);
  }
  
  .card2:hover p {
    color: black;
  }
  
  .card2:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
      0 15px 24px var(--box-shadow-color);
  }
  
  .circulo2 {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: #ececec;
    border: 2px solid var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
  }
  
  
  
  .cor__cobertura2 {
    width: 114px;
    height: 114px;
    position: absolute;
    border-radius: 50%;
    background: white;
    top: 70px;
    left: 50px;
    z-index: 0;
    transition: transform 0.3s ease-out;
  }